

.daycol {
  min-width: 135px;
  label {
    display: inline;
  }
}

.datecol {
  min-width: 110px;
}

.timecol {
  min-width: 125px;
}

.ptocol {
  min-width: 100px;
}

.title-area {
  margin-top: 10px;
  margin-bottom: 10px;
}