

$asphalt: #34495e;
$blue: #3498db;


.timepicker {
  user-select: none;
  box-shadow: 0 0 0 1px rgba(14,41,57,0.12),0 2px 5px rgba(14,41,57,0.44),inset 0 -1px 2px rgba(14,41,57,0.15);
  background: rgb(254, 254, 254);
  margin: 0 auto;
  border-radius: 0.1px;
  width: 270px;
  height: 130px;
  font-size: 14px;
  display: none;
  &__title {
    @extend .text-center;
    position: relative;
    background: #f2f2f2 linear-gradient(to bottom, #ffffff 0%, #f2f2f2 100%);
    margin: 0 auto;
    border-bottom: 1px solid #e5e5e5;
    padding: 12px 11px 10px 15px;
    color: #4C4C4C;
    font-size: inherit;
  }
  &__close {
    transform: translateY(-25%);
    position: absolute;
    top: 25%;
    right: 10px;
    color: $asphalt;
    cursor: pointer;
    &:before {
      content: '\00d7';
    }
  }
  &__clear {
    transform: translateY(-25%);
    position: absolute;
    top: 25%;
    left: 10px;
    color: $asphalt;
    cursor: pointer;
  }
  &__controls {
    @extend .text-center;
    padding: 10px 0;
    line-height: normal;
    margin: 0;
    &__control {
      @extend .text-center;
      vertical-align: middle;
      display: inline-block;
      font-size: inherit;
      margin: 0 auto;
      width: 35px;
      letter-spacing: 1.3px;
      &-up, &-down {
        @extend .text-center;
        color: $asphalt;
        position: relative;
        display: block;
        margin: 3px auto;
        font-size: 18px;
        cursor: pointer;
      }
      &-up:before {
        @extend .fontello;
        content: '\e800';
      }
      &-down:after {
        @extend .fontello-after;
        content: '\e801';
      }
      &--separator {
        @extend .timepicker__controls__control;
        width: 5px;
      }
    }
  }
}

.text-center {
  text-align: center;
}

.hover-state {
  color: $blue;
}

.time-key-entry {
  background-color: $blue;
  color: #fefefe
}

@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.eot?52602240');
  src: url('../fonts/fontello.eot?52602240#iefix') format('embedded-opentype'),
  url('../fonts/fontello.woff?52602240') format('woff'),
  url('../fonts/fontello.ttf?52602240') format('truetype'),
  url('../fonts/fontello.svg?52602240#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

.fontello:before, .fontello-after:after {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}